import React from "react";

import logo from '../Resources/logo-black.png';
import '../Resources/MainLogo.css';

const MainLogo = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
    </div>
  );
};

export default MainLogo;
