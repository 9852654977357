import React from "react";
import { Message, Card, Header, Container } from "semantic-ui-react";

const style = {
  paddingTop: "5vh",
  minHeight: "80vh",
};

const textStyle = {
  color: "#40662A",
  backGroundColor: "#7EC357",
};

const teamMembers = [
  {
    header: "Diana Răileanu",
    meta: "Co-Founder",
    description: "Diana enjoys painting fish.",
  },
  {
    header: "Zisis Katsavelis",
    meta: "Human being",
    description: "Zisis is dreaming of visiting Bahamas.",
  },
];

const renderCards = (cards) =>
  cards.map((card, index) => {
    return (
      <Card color="green" key={index}>
        <Card.Content>
          <Card.Header content={card.header} />
          <Card.Meta content={card.meta} />
          <Card.Description content={card.description} />
        </Card.Content>
      </Card>
    );
  });

const About = () => {
  return (
    <Container style={style}>
      <Message color="green" floating>
        <Message.Header style={textStyle}>About.</Message.Header>
        <Message.Content style={textStyle}>
          Plain software. Nothing special, just as you dreamt of it.
        </Message.Content>
      </Message>
      <Header as="h2" textAlign="center">
        Meet the team.
      </Header>
      <Card.Group centered>{renderCards(teamMembers)}</Card.Group>
    </Container>
  );
};

export default About;
