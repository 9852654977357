import React from "react";
import { Container, Divider } from "semantic-ui-react";
const style = {
  position: "fixed",
  bottom: "0",
};

const Footer = () => {
  return (
    <Container style={style} textAlign="center">
      <Divider fitted />
      <div style={ {color: "LightGray"}}>
        <div>All rights are not reserved. Call us to reserve them.</div>
        <div>COPYRIGHT DiZi © 2020.</div>
      </div>
    </Container>
  );
};

export default Footer;
