import React from "react";
import { Route, HashRouter } from "react-router-dom";
import { Container, Divider } from "semantic-ui-react";

import Footer from "./Footer";
import Header from "./Header";
import About from "./About";
import MainLogo from "./MainLogo";

function App() {
  return (
    <Container>
      <HashRouter>
        <Header />
        <Divider fitted />
        <Route path="/" exact component={MainLogo} />
        <Route path="/about" component={About} />
      </HashRouter>
      <Footer />
    </Container>
  );
}

export default App;
