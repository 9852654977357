import React from "react";
import { Container, Icon } from "semantic-ui-react";
import HeaderButton from "./HeaderButton";
import LogoType from "./LogoType";

class Header extends React.PureComponent {
  constructor() {
    super();
    this.isMobile = window.innerWidth < 480;
  }

  render() {
    return (
      <Container style={{ padding: "2vh" }}>
        <LogoType isMobile={this.isMobile} />
        <HeaderButton
          isMobile={this.isMobile}
          floated="right"
          secondary={<Icon name="info" size="large" />}
          primary="About"
          navigateTo="/about"
        />
        <HeaderButton
          isMobile={this.isMobile}
          floated="right"
          secondary={<Icon name="home" size="large" />}
          primary={"Home"}
          navigateTo="/"
        />
      </Container>
    );
  }
}

export default Header;
