import React from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

const HeaderButton = ({ primary, secondary, navigateTo, isMobile }) => {
  return (
    <Link to={navigateTo}>
      <Button
        animated
        compact
        size={isMobile ? "mini" : "medium"}
        style={{ color: "white", backgroundColor: "#7EC357" }}
        floated="right"
      >
        <Button.Content visible>{primary}</Button.Content>
        <Button.Content hidden>{secondary}</Button.Content>
      </Button>
    </Link>
  );
};

export default HeaderButton;
