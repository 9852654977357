import React from "react";

const styleTeal = {
  color: "#11CAC6",
  fontSize: "xx-large",
};

const styleGreen = {
  color: "#7EC357",
  fontSize: "xx-large",
};

const styleTealMobile = {
  color: "#11CAC6",
  fontSize: "x-large",
};

const styleGreenMobile = {
  color: "#7EC357",
  fontSize: "x-large",
};

const LogoType = ({isMobile}) => {
  return (
    <React.Fragment>
      <span style={isMobile? styleTealMobile : styleTeal}>green</span>
      <span style={styleTeal}>&nbsp;</span>
      <span style={isMobile? styleGreenMobile : styleGreen}>square</span>
    </React.Fragment>
  );
};

export default LogoType;
